<template>
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13.56C3 8.58197 3 6.09295 4.54648 4.54648C6.09295 3 8.58197 3 13.56 3H14.44C19.418 3 21.907 3 23.4535 4.54648C25 6.09295 25 8.58197 25 13.56V14.44C25 19.418 25 21.907 23.4535 23.4535C21.907 25 19.418 25 14.44 25H13.56C8.58197 25 6.09295 25 4.54648 23.4535C3 21.907 3 19.418 3 14.44V13.56Z"
      fill="white"
    />
    <path
      d="M14.7067 18.9C9.68424 18.9 6.81956 15.4465 6.7002 9.69995H9.21599C9.29862 13.9178 11.1533 15.7043 12.6224 16.0727V9.69995H14.9913V13.3376C16.4421 13.181 17.9661 11.5234 18.4802 9.69995H20.8492C20.4544 11.947 18.8017 13.6046 17.6264 14.2861C18.8017 14.8387 20.684 16.2845 21.4002 18.9H18.7925C18.2324 17.1502 16.8369 15.7964 14.9913 15.6123V18.9H14.7067Z"
      fill="#0077FF"
    />
  </svg>
</template>
