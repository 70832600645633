<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1.5"
      width="61"
      height="61"
      rx="30.5"
      stroke="#009C5A"
      stroke-width="3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.7805 25.0059C40.5437 25.5511 40.7205 26.6119 40.1753 27.3751L31.6834 39.2638C31.373 39.6984 30.8762 39.9617 30.3422 39.9745C29.8082 39.9873 29.2993 39.7482 28.9684 39.329L23.8732 32.8752C23.292 32.139 23.4177 31.071 24.1539 30.4898C24.8901 29.9085 25.9581 30.0342 26.5393 30.7704L30.2335 35.4497L37.4113 25.4008C37.9565 24.6375 39.0172 24.4607 39.7805 25.0059Z"
      fill="#009C5A"
    />
  </svg>
</template>
