<template>
  <SharedPopupContainer @hide="hide">
    <SharedPopup
      v-if="!isMobile"
      :max-width="510"
    >
      <SharedPopupHeader @hide="hide" />
      <div :class="$style.container">
        <IconSuccess />
        <SharedHeadingHeading3 :class="$style.heading">
          {{ $t("top_up_balance.success_popup.title") }}
        </SharedHeadingHeading3>
        <p :class="$style.text">
          {{ $t("top_up_balance.success_popup.text") }}
        </p>
        <SharedButtonPrimaryButton
          :class="$style.button"
          size="xl"
          @click="hide"
          >{{
            $t("top_up_balance.success_popup.ok")
          }}</SharedButtonPrimaryButton
        >
      </div>
    </SharedPopup>
    <SharedBottomSheet v-else>
      <SharedPopupHeader @hide="hide" />
      <div :class="$style.container">
        <IconSuccess />
        <SharedHeadingHeading4 :class="$style.heading">
          {{ $t("top_up_balance.success_popup.title") }}
        </SharedHeadingHeading4>
        <p :class="$style.text">
          {{ $t("top_up_balance.success_popup.text") }}
        </p>
        <SharedButtonPrimaryButton
          :class="$style.button"
          size="l"
          @click="hide"
          >{{
            $t("top_up_balance.success_popup.ok")
          }}</SharedButtonPrimaryButton
        >
      </div>
    </SharedBottomSheet>
  </SharedPopupContainer>
</template>
<script setup lang="ts">
const paymentsStore = usePaymentsStore();
const hide = () => (paymentsStore.successPopupRequired = false);
const isMobile = useMobileCheck();
</script>
<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-4xl) var(--spacing-5xl);
  text-align: center;
}
.heading {
  margin-top: var(--spacing-5xl);
}
.text {
  width: 200px;
  margin-top: var(--spacing-4xl);
}
.button {
  width: 100%;
  margin-top: var(--spacing-7xl);
  @media screen and (max-width: $mobile) {
    margin-top: var(--spacing-5xl);
  }
}
</style>
