<template>
  <div
    ref="container"
    :class="$style.popup"
  >
    <div :class="$style.popup_body">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.popup {
  width: 100%;
  max-width: 800px;
  min-height: 40vh;
  max-height: 90vh;
  background: var(--general-underlay-1-lvl);
  border-radius: var(--radius-5xl) var(--radius-5xl) var(--radius-none)
    var(--radius-none);
  overflow: auto;
  clear: both;
  position: relative;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  color: var(--general-transparent-light-100);
  padding: var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl)
    var(--spacing-4xl);

  &_title {
    margin: 0;
  }
  &_body {
    overflow: auto;
    height: 0;
    flex: 1 1;
  }
}
.close_button {
  position: absolute;
}
</style>
