<template>
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.694 9.84239C16.7422 8.93237 15.5316 8.46901 14.1833 8.46901C11.7914 8.46901 9.76717 10.0844 9.04499 12.2551C8.86132 12.8061 8.75696 13.3947 8.75696 14C8.75696 14.6053 8.86095 15.1939 9.04462 15.7449C9.76679 17.9156 11.7914 19.5311 14.1833 19.5311C15.4189 19.5311 16.4712 19.2056 17.2936 18.6545C18.2662 18.0033 18.9133 17.0307 19.1262 15.8827H14.1837V12.3303H22.833C22.9416 12.9314 23 13.5576 23 14.2088C23 17.0057 21.9981 19.36 20.2616 20.9588C18.7421 22.3614 16.6629 23.1837 14.1833 23.1837C10.5933 23.1837 7.48757 21.1257 5.97644 18.1243C5.35445 16.8845 5 15.4819 5 14C5 12.5181 5.35482 11.1155 5.97681 9.87572C7.48794 6.87432 10.5933 4.81641 14.1833 4.81641C16.6587 4.81641 18.7376 5.72643 20.328 7.20834L17.694 9.84239Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
