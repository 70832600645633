<template>
  <SharedPopupContainer @hide="hide">
    <SharedPopup
      v-if="!isMobile"
      :max-width="510"
    >
      <SharedPopupHeader @hide="hide" />
      <div :class="$style.container">
        <IconFailure />
        <SharedHeadingHeading3 :class="$style.heading">
          {{ $t("top_up_balance.failure_popup.title") }}
        </SharedHeadingHeading3>
        <p :class="$style.text">
          {{ $t("top_up_balance.failure_popup.text") }}
        </p>
        <SharedButtonPrimaryButton
          :class="$style.button"
          size="xl"
          @click="retry"
          >{{
            $t("top_up_balance.failure_popup.ok")
          }}</SharedButtonPrimaryButton
        >
        <SharedButtonGhostButton
          size="xl"
          :class="$style.supportButton"
          @click="openSupport"
          ><IconSupport />
          {{
            $t("top_up_balance.failure_popup.support")
          }}</SharedButtonGhostButton
        >
      </div>
    </SharedPopup>
    <SharedBottomSheet v-else>
      <SharedPopupHeader @hide="hide" />
      <div :class="$style.container">
        <IconFailure />
        <SharedHeadingHeading4 :class="$style.heading">
          {{ $t("top_up_balance.failure_popup.title") }}
        </SharedHeadingHeading4>
        <p :class="$style.text">
          {{ $t("top_up_balance.failure_popup.text") }}
        </p>
        <SharedButtonPrimaryButton
          :class="$style.button"
          size="l"
          @click="retry"
          >{{
            $t("top_up_balance.failure_popup.ok")
          }}</SharedButtonPrimaryButton
        >
        <SharedButtonGhostButton
          size="l"
          @click="openSupport"
          ><IconSupport />
          {{
            $t("top_up_balance.failure_popup.support")
          }}</SharedButtonGhostButton
        >
      </div>
    </SharedBottomSheet>
  </SharedPopupContainer>
</template>
<script setup lang="ts">
const paymentsStore = usePaymentsStore();
const productStore = useProductsStore();
const isMobile = useMobileCheck();
const hide = () => (paymentsStore.failurePopupRequired = false);
const openSupport = () => {
  if (window.TalkMe) {
    window.TalkMe("openSupport");
  }
};

const retry = async () => {
  try {
    await productStore.continueOrder(productStore.orderId);
  } catch (e: any) {
    /* empty */
  } finally {
    hide();
  }
};
</script>
<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-4xl) var(--spacing-5xl);
  text-align: center;
}
.heading {
  margin-top: var(--spacing-5xl);
}
.text {
  width: 200px;
  margin-top: var(--spacing-4xl);
}
.button {
  width: 100%;
  margin-top: var(--spacing-7xl);
  @media screen and (max-width: $mobile) {
    margin-top: var(--spacing-5xl);
  }
}
.supportButton {
  margin-top: var(--spacing-xs);
  width: 100%;
}
</style>
