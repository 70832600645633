<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1.5"
      width="61"
      height="61"
      rx="30.5"
      stroke="#FF3333"
      stroke-width="3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5436 25.5435C26.0183 25.069 26.8491 24.9165 27.4933 25.5605L32.003 30.0682L36.5127 25.5604C37.1569 24.9164 37.9707 25.052 38.4623 25.5435C38.937 26.0179 39.0896 26.8483 38.4454 27.4923L33.9357 32L38.4453 36.5077C39.0896 37.1517 38.937 37.9821 38.4623 38.4565C37.9706 38.948 37.1569 39.0836 36.5126 38.4396L32.003 33.9319L27.4934 38.4395C26.8491 39.0835 26.0184 38.931 25.5437 38.4565C25.052 37.965 24.9164 37.1516 25.5606 36.5077L30.0702 32L25.5606 27.4923C24.9164 26.8484 25.052 26.035 25.5436 25.5435Z"
      fill="#FF3333"
    />
  </svg>
</template>
