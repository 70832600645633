<template>
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8579 7.44011H14.454C11.8802 7.44011 10.5265 8.74373 10.5265 10.6657C10.5265 12.8384 11.4624 13.8564 13.3844 15.1616L14.9721 16.2312L10.4095 23.0486H7L11.0947 16.9499C8.73968 15.2618 7.41783 13.6224 7.41783 10.8496C7.41783 7.37326 9.84123 5 14.4373 5H19V23.0319H15.8579V7.44011Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
